import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';
import get from "lodash.get";

export const initializeDeviceImages = () => {
    return {
        type: 'DEVICE_IMAGES_INITIALIZE',
    }
}

export const uploadImage = (file) => {
    return {
        type: 'DEVICE_IMAGES_UPLOAD_FILE',
        payload: file
    }
}

export const setTitle = (id, title) => {
    return {
        type: 'DEVICE_IMAGES_SET_TITLE',
        payload: {id, title}
    }
}

export const saveTitle = (id) => {
    return {
        type: 'DEVICE_IMAGES_SAVE_TITLE',
        payload: id
    }
}

export const removeImage = (id) => {
    return {
        type: 'DEVICE_IMAGES_REMOVE_FILE',
        payload: id
    }
}

export function* getDeviceImages(){
    function* fetchDeviceImagesData(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            yield put({type: 'DEVICE_IMAGES_FETCHING'});
            const device = yield select(state => state.devices.activeRow);
            const url = '/api/device/'+device.id+'/images';
            const device_images = yield call(fetchData, {url: url, method: 'GET', jwt: jwt});
            yield put({type: 'DEVICE_IMAGES_FETCHED', payload: device_images});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin ảnh thiết bị');
        }
    }

    yield takeLatest('DEVICE_IMAGES_INITIALIZE', fetchDeviceImagesData);
}

export function* saveDeviceImageData(){
    function* saveDeviceImage(file){
        const formData = new FormData();
        formData.append('image', file);
        const jwt = yield select(state => state.auth.jwt_token);
        const device = yield select(state => state.devices.activeRow);
        const url = '/api/device/'+device.id+'/images';
        try {
            yield put({type: 'DEVICE_IMAGES_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: formData, is_json: false});
            yield put({type: 'DEVICE_IMAGES_SAVED'});
            toastr.success('Upload ảnh thành công');
            yield put({type: 'DEVICE_IMAGES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi upload ảnh');
            yield put({type: 'DEVICE_IMAGES_SAVED'});
        }
    }

    yield takeLatest('DEVICE_IMAGES_UPLOAD_FILE', (action) => saveDeviceImage(action.payload));
}

export function* updateDeviceImageData(){
    function* updateDeviceImage(id){
        const {jwt, titles} = yield select(state => ({jwt: state.auth.jwt_token, titles: state.device_images.titles}));
        const device = yield select(state => state.devices.activeRow);
        const url = '/api/device/'+device.id+'/image/'+id;

        try {
            yield put({type: 'DEVICE_IMAGES_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: {title: get(titles, 'title_'+id, null)}});
            yield put({type: 'DEVICE_IMAGES_SAVED'});
            toastr.success('Lưu thông tin ảnh thiết bị thành công');
            yield put({type: 'DEVICE_IMAGES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin ảnh thiết bị');
            yield put({type: 'DEVICE_IMAGES_SAVED'});
        }
    }

    yield takeLatest('DEVICE_IMAGES_SAVE_TITLE', (action) => updateDeviceImage(action.payload));
}

export function* removeDeviceImageData(){
    function* removeDeviceImage(id){
        const jwt = yield select(state => state.auth.jwt_token);
        const device = yield select(state => state.devices.activeRow);
        const url = '/api/device/'+device.id+'/image/'+id;

        try {
            yield put({type: 'DEVICE_IMAGES_REMOVING'});
            yield call(fetchData, {url: url, method: 'DELETE', jwt: jwt});
            yield put({type: 'DEVICE_IMAGES_REMOVED'});
            toastr.success('Xóa thông tin ảnh thiết bị thành công');
            yield put({type: 'DEVICE_IMAGES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin ảnh thiết bị');
            yield put({type: 'DEVICE_IMAGES_REMOVED'});
        }
    }

    yield takeLatest('DEVICE_IMAGES_REMOVE_FILE', (action) => removeDeviceImage(action.payload));
}
