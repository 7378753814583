import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializeDefaultConfiguration = () => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_INITIALIZE',
    }
}

export const setPage = (page) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_SET_PAGE',
        payload: page
    }
}

export const setLimit = (limit) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = (data) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'DEFAULT_CONFIGURATIONS_REMOVE_DATA'
    }
}

export function* getDefaultConfigurations(){
    function* fetchDefaultConfigurationsData(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            yield put({type: 'DEFAULT_CONFIGURATIONS_FETCHING'});
            const default_configurations = yield call(fetchData, {url: '/api/default-configurations', method: 'GET', jwt: jwt});
            yield put({type: 'DEFAULT_CONFIGURATIONS_FETCHED', payload: default_configurations});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin mẫu cấu hình');
        }
    }

    yield takeLatest('DEFAULT_CONFIGURATIONS_INITIALIZE', fetchDefaultConfigurationsData);
}

export function* saveDefaultConfigurationData(){
    function* saveDefaultConfiguration(){
        const jwt = yield select(state => state.auth.jwt_token);
        const default_configuration = yield select(state => state.default_configurations.activeRow);
        const url = default_configuration.id > 0 ? ('/api/default-configuration/'+default_configuration.id) : '/api/default-configurations';
        try {
            yield put({type: 'DEFAULT_CONFIGURATIONS_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: default_configuration});
            yield put({type: 'DEFAULT_CONFIGURATIONS_SAVED'});
            toastr.success('Lưu thông tin mẫu cấu hình thành công');
            yield put({type: 'DEFAULT_CONFIGURATIONS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin mẫu cấu hình');
            yield put({type: 'DEFAULT_CONFIGURATIONS_SAVED'});
        }
    }

    yield takeLatest('DEFAULT_CONFIGURATIONS_SAVE_DATA', saveDefaultConfiguration);
}

export function* removeDefaultConfigurationData(){
    function* removeDefaultConfiguration(){
        const jwt = yield select(state => state.auth.jwt_token);
        const default_configuration = yield select(state => state.default_configurations.activeRow);

        try {
            yield put({type: 'DEFAULT_CONFIGURATIONS_REMOVING'});
            yield call(fetchData, {url: '/api/default-configuration/'+default_configuration.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'DEFAULT_CONFIGURATIONS_REMOVED'});
            toastr.success('Xóa thông tin mẫu cấu hình thành công');
            yield put({type: 'DEFAULT_CONFIGURATIONS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin mẫu cấu hình');
            yield put({type: 'DEFAULT_CONFIGURATIONS_REMOVED'});
        }
    }

    yield takeLatest('DEFAULT_CONFIGURATIONS_REMOVE_DATA', removeDefaultConfiguration);
}
