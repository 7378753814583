import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializeCustomer = () => {
    return {
        type: 'CUSTOMERS_INITIALIZE',
    }
}

export const setPage = (page) => {
    return {
        type: 'CUSTOMERS_SET_PAGE',
        payload: page
    }
}

export const setLimit = (limit) => {
    return {
        type: 'CUSTOMERS_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'CUSTOMERS_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'CUSTOMERS_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'CUSTOMERS_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'CUSTOMERS_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = (data) => {
    return {
        type: 'CUSTOMERS_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'CUSTOMERS_REMOVE_DATA'
    }
}

export function* getCustomers(){
    function* fetchCustomersData(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            yield put({type: 'CUSTOMERS_FETCHING'});
            const customers = yield call(fetchData, {url: '/api/customers', method: 'GET', jwt: jwt});
            yield put({type: 'CUSTOMERS_FETCHED', payload: customers});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin khách hàng');
        }
    }

    yield takeLatest('CUSTOMERS_INITIALIZE', fetchCustomersData);
}

export function* saveCustomerData(){
    function* saveCustomer(){
        const jwt = yield select(state => state.auth.jwt_token);
        const customer = yield select(state => state.customers.activeRow);
        const url = customer.id > 0 ? ('/api/customer/'+customer.id) : '/api/customers';
        try {
            yield put({type: 'CUSTOMERS_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: customer});
            yield put({type: 'CUSTOMERS_SAVED'});
            toastr.success('Lưu thông tin khách hàng thành công');
            yield put({type: 'CUSTOMERS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin khách hàng');
            yield put({type: 'CUSTOMERS_SAVED'});
        }
    }

    yield takeLatest('CUSTOMERS_SAVE_DATA', saveCustomer);
}

export function* removeCustomerData(){
    function* removeCustomer(){
        const jwt = yield select(state => state.auth.jwt_token);
        const customer = yield select(state => state.customers.activeRow);

        try {
            yield put({type: 'CUSTOMERS_REMOVING'});
            yield call(fetchData, {url: '/api/customer/'+customer.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'CUSTOMERS_REMOVED'});
            toastr.success('Xóa thông tin khách hàng thành công');
            yield put({type: 'CUSTOMERS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin khách hàng');
            yield put({type: 'CUSTOMERS_REMOVED'});
        }
    }

    yield takeLatest('CUSTOMERS_REMOVE_DATA', removeCustomer);
}
