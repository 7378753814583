import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from 'actions/auth';

class ProfileForm extends React.Component {
    constructor(props){
        super(props);

        const {dispatch} = props;
        this.actions = bindActionCreators(actions, dispatch);
    }

    componentDidMount(){
      this.actions.initializeProfile();
    }

    render(){
        return (<div>
            <div className="field">
                <label className="label">Email</label>
                <div className="control">
                    <input
                        className="input"
                        type="text"
                        placeholder="Địa chỉ email"
                        readOnly={true}
                        value={this.props.profile.email || ""} />
                </div>
            </div>
            <div className="field">
                <label className="label">Tên</label>
                <div className="control">
                    <input
                        className="input"
                        type="text"
                        placeholder="Tên người dùng"
                        value={this.props.profile.name || ""}
                        onChange={(e) => this.actions.updateProfile({name: e.target.value})} />
                </div>
            </div>
            <div className="field">
                <label className="label">Mật khẩu</label>
                <div className="control">
                    <input
                        className="input"
                        type="password"
                        placeholder="Mật khẩu"
                        value={this.props.profile.password || ""}
                        onChange={(e) => this.actions.updateProfile({password: e.target.value})} />
                </div>
            </div>
            <div className="field">
                <label className="label">Nhập lại mật khẩu</label>
                <div className="control">
                    <input
                        className="input"
                        type="password"
                        placeholder="Nhập lại mật khẩu"
                        value={this.props.profile.confirmpwd || ""}
                        onChange={(e) => this.actions.updateProfile({confirmpwd: e.target.value})} />
                </div>
            </div>
            <div className="field">
                <label className="label">Điện thoại</label>
                <div className="control">
                    <input
                        className="input"
                        type="text"
                        placeholder="Số điện thoại"
                        value={this.props.profile.phone_number || ""}
                        onChange={(e) => this.actions.updateProfile({phone_number: e.target.value})} />
                </div>
            </div>
            <div className="field">
                <label className="label">Địa chỉ</label>
                <div className="control">
                    <input
                        className="input"
                        type="text"
                        placeholder="Địa chỉ"
                        value={this.props.profile.address || ""}
                        onChange={(e) => this.actions.updateProfile({address: e.target.value})} />
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.auth
    }
  };
  
export default connect(mapStateToProps)(ProfileForm);
