const defaultState = {
    ajaxLoading: false,
    ajaxLoaded: false,
    customers: [],
    page: 1,
    limit: 30,
    showEditModal: false,
    showRemoveModal: false,
    activeRow: {},
};

const customers = (state = defaultState, action) => {
    switch (action.type) {
        case 'CUSTOMERS_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'CUSTOMERS_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'CUSTOMERS_SHOW_EDIT_MODAL':
            return Object.assign({}, state, { showEditModal: action.payload });
        case 'CUSTOMERS_SHOW_REMOVE_MODAL':
            return Object.assign({}, state, { showRemoveModal: action.payload });
        case 'CUSTOMERS_SET_ACTIVE_ROW':
            return Object.assign({}, state, { activeRow: action.payload });
        case 'CUSTOMERS_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'CUSTOMERS_FETCHING':
        case 'CUSTOMERS_SAVING':
        case 'CUSTOMERS_REMOVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'CUSTOMERS_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ajaxLoaded: true, customers: action.payload });
        case 'CUSTOMERS_SAVED':
            return Object.assign({}, state, { ajaxLoading: false, showEditModal: false });
        case 'CUSTOMERS_REMOVED':
            return Object.assign({}, state, { ajaxLoading: false, showRemoveModal: false });
        default:
            return state;
    }
};

export default customers;
