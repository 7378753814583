const defaultState = {
    ajaxLoading: false,
    ajaxLoaded: false,
    locations: [],
    page: 1,
    limit: 30,
    showEditModal: false,
    showLogsModal: false,
    showRemoveModal: false,
    activeRow: {},
    search: "",
};

const locations = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOCATIONS_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'LOCATIONS_SET_SEARCH':
            return Object.assign({}, state, { search: action.payload });
        case 'LOCATIONS_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'LOCATIONS_SHOW_EDIT_MODAL':
            return Object.assign({}, state, { showEditModal: action.payload });
        case 'LOCATIONS_SHOW_LOGS_MODAL':
            return Object.assign({}, state, { showLogsModal: action.payload });
        case 'LOCATIONS_SHOW_REMOVE_MODAL':
            return Object.assign({}, state, { showRemoveModal: action.payload });
        case 'LOCATIONS_SET_ACTIVE_ROW':
            return Object.assign({}, state, { activeRow: action.payload });
        case 'LOCATIONS_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'LOCATIONS_FETCHING':
        case 'LOCATIONS_SAVING':
        case 'LOCATIONS_REMOVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'LOCATIONS_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ajaxLoaded: true, locations: action.payload });
        case 'LOCATIONS_SAVED':
            return Object.assign({}, state, { ajaxLoading: false, showEditModal: false });
        case 'LOCATIONS_REMOVED':
            return Object.assign({}, state, { ajaxLoading: false, showRemoveModal: false });
        default:
            return state;
    }
};

export default locations;
