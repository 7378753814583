export const toggleNav = () => {
  return {
    type: 'NAV_TOGGLE_ACTIVE'
  };
};

export const onLogoutClick = () => {
  if (typeof (Storage) !== 'undefined') {
    sessionStorage.removeItem('jwt_token');
  }
  return {
    type: 'AUTH_LOGOUT'
  };
};

export const showProfileModal = (show) => {
  return {
    type: 'AUTH_SHOW_PROFILE_MODAL',
    payload: show
  }
}