import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as navActions from 'actions/nav';
import LogoImg from 'images/wifimedia-logo-300x122.png';

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    this.actions = bindActionCreators(navActions, dispatch);
  }

  render() {
    const logoutElement = (
      <div className="navbar-item">
        <div className="field is-grouped">
          <p className="control" title="Sửa thông tin cá nhân">
            <button onClick={() => this.actions.showProfileModal(true)} className="button is-link">
              <span id="sign-out-btn">{this.props.userName}</span>
            </button>
          </p>
          <p className="control" title="Sign out">
            <button onClick={this.actions.onLogoutClick} className="button is-danger">
              <span className="icon">
                <i className="fa fa-sign-out" />
              </span>
            </button>
          </p>
        </div>
      </div>
    );

    const activeClass = this.props.isNavActive ? ' is-active' : '';

    return (
      <nav className="navbar is-transparent">
        <div className="navbar-brand">
          <NavLink className="navbar-item wifimedia-navbar" to="/">
            <img alt="Wifimedia" src={LogoImg} />
          </NavLink>
          <div
            role="button"
            tabIndex={0}
            className={`navbar-burger burger${activeClass}`}
            onClick={this.actions.toggleNav}
          >
            <span />
            <span />
            <span />
          </div>
        </div>

        <div id="mainNavBar" className={`navbar-menu${activeClass}`}>
          <div className="navbar-start">

          </div>
          <div className="navbar-end">
            <NavLink activeClassName="has-text-link" exact className="navbar-item" to="/">
              <span className="icon">
                <i className="fa fa-home" />
              </span>
              <span>Trang chủ</span>
            </NavLink>
            {this.props.permissions.user.view ? (
            <NavLink activeClassName="has-text-link" className="navbar-item" to="/users">
              <span className="icon">
                <i className="fa fa-user" />
              </span>
              <span>Users</span>
            </NavLink> ) : null}
            {this.props.permissions.customer.view ? (
            <NavLink activeClassName="has-text-link" className="navbar-item" to="/customers">
              <span className="icon">
                <i className="fa fa-users" />
              </span>
              <span>Đối tác</span>
            </NavLink> ) : null}
            {this.props.permissions.location.view ? (
            <NavLink activeClassName="has-text-link" className="navbar-item" to="/locations">
              <span className="icon">
                <i className="fa fa-map-marker" />
              </span>
              <span>Địa điểm</span>
            </NavLink> ) : null}
            {this.props.permissions.device.view ? (
            <NavLink activeClassName="has-text-link" className="navbar-item" to="/devices">
              <span className="icon">
                <i className="fa fa-sitemap" />
              </span>
              <span>Thiết bị</span>
            </NavLink> ) : null}
            {logoutElement}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userName: state.auth.userName,
        isNavActive: state.nav.isActive,
        permissions: state.auth.permissions,
    };
};

export default connect(mapStateToProps)(NavBar);