import Loadable from 'react-loadable';
import Loading from 'views/elements/Loading';

import MainLayout from 'views/layouts/MainLayout';
import EmptyLayout from 'views/layouts/EmptyLayout';

const LoginView = Loadable({
    loader: () => import('views/Login'),
    loading: Loading
});
const HomeView = Loadable({
    loader: () => import('views/Home'),
    loading: Loading
});
const UserView = Loadable({
    loader: () => import('views/User'),
    loading: Loading
});
const CustomerView = Loadable({
    loader: () => import('views/Customer'),
    loading: Loading
});
const LocationView = Loadable({
    loader: () => import('views/Location'),
    loading: Loading
});
const DeviceView = Loadable({
    loader: () => import('views/Device'),
    loading: Loading
});

const routes = [
  {
    path: '/',
    exact: true,
    layout: MainLayout,
    title: 'Home Page',
    component: HomeView
  },
  {
    path: '/users',
    layout: MainLayout,
    title: 'Quản lý users',
    component: UserView
  },
  {
    path: '/customers',
    layout: MainLayout,
    title: 'Quản lý đối tác',
    component: CustomerView
  },
  {
    path: '/locations',
    layout: MainLayout,
    title: 'Quản lý nhà hàng',
    component: LocationView
  },
  {
    path: '/devices/:location_id?',
    layout: MainLayout,
    title: 'Quản lý thiết bị',
    component: DeviceView
  },
  {
    path: '/login',
    layout: EmptyLayout,
    title: 'Đăng nhập',
    loginRequired: false,
    component: LoginView
  }
];

export default routes;