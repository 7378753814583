import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializeLocation = () => {
    return {
        type: 'LOCATIONS_INITIALIZE',
    }
}

export const setPage = (page) => {
    return {
        type: 'LOCATIONS_SET_PAGE',
        payload: page
    }
}

export const setSearchParam = (search) => {
    return {
        type: 'LOCATIONS_SET_SEARCH',
        payload: search
    }
}

export const setLimit = (limit) => {
    return {
        type: 'LOCATIONS_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'LOCATIONS_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showLogsModal = (show) => {
    return {
        type: 'LOCATIONS_SHOW_LOGS_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'LOCATIONS_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'LOCATIONS_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'LOCATIONS_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = (data) => {
    return {
        type: 'LOCATIONS_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'LOCATIONS_REMOVE_DATA'
    }
}

export const toggleStatus = (row) => {
    return {
        type: 'LOCATIONS_TOGGLE_STATUS'
    }
}

export function* getLocations(){
    function* fetchLocationsData(){
        const {jwt, search} = yield select(state => ({jwt: state.auth.jwt_token, search: state.locations.search}));
        try {
            yield put({type: 'LOCATIONS_FETCHING'});
            const locations = yield call(fetchData, {url: '/api/locations?search='+encodeURIComponent(search), method: 'GET', jwt: jwt});
            yield put({type: 'LOCATIONS_FETCHED', payload: locations});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin địa điểm');
        }
    }

    yield takeLatest('LOCATIONS_INITIALIZE', fetchLocationsData);
}

export function* saveLocationData(){
    function* saveLocation(){
        const jwt = yield select(state => state.auth.jwt_token);
        const location = yield select(state => state.locations.activeRow);
        const url = location.id > 0 ? ('/api/location/'+location.id) : '/api/locations';
        try {
            yield put({type: 'LOCATIONS_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: location});
            yield put({type: 'LOCATIONS_SAVED'});
            toastr.success('Lưu thông tin địa điểm thành công');
            yield put({type: 'LOCATIONS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin địa điểm');
            yield put({type: 'LOCATIONS_SAVED'});
        }
    }

    yield takeLatest('LOCATIONS_SAVE_DATA', saveLocation);
}

export function* removeLocationData(){
    function* removeLocation(){
        const jwt = yield select(state => state.auth.jwt_token);
        const location = yield select(state => state.locations.activeRow);

        try {
            yield put({type: 'LOCATIONS_REMOVING'});
            yield call(fetchData, {url: '/api/location/'+location.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'LOCATIONS_REMOVED'});
            toastr.success('Xóa thông tin địa điểm thành công');
            yield put({type: 'LOCATIONS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin địa điểm');
            yield put({type: 'LOCATIONS_REMOVED'});
        }
    }

    yield takeLatest('LOCATIONS_REMOVE_DATA', removeLocation);
}

export function* toggleLocationStatus(){
    function* toggleRequest(){
        const jwt = yield select(state => state.auth.jwt_token);
        const location = yield select(state => state.locations.activeRow);
        const url = '/api/location/'+location.id+'/toggle_status';
        try {
            yield put({type: 'LOCATIONS_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt});
            yield put({type: 'LOCATIONS_SAVED'});
            toastr.success('Lưu thông tin địa điểm thành công');
            yield put({type: 'LOCATIONS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin địa điểm');
            yield put({type: 'LOCATIONS_SAVED'});
        }
    }

    yield takeLatest('LOCATIONS_TOGGLE_STATUS', toggleRequest);
}
