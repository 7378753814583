import { all } from 'redux-saga/effects';

import { loginSaga, getPermissions, getProfile, saveProfileSaga } from './auth';
import { getGroups, saveGroupData, removeGroupData } from './groups';
import { getCustomers, saveCustomerData, removeCustomerData } from './customers';
import { getUsers, saveUserData, removeUserData } from './users';
import { getLocations, saveLocationData, removeLocationData, toggleLocationStatus } from './locations';
import { getLocationLogs, saveLocationLogData } from './location_logs';
import { 
  getDevices, saveDeviceData, saveDeviceConfig, removeDeviceData, getDeviceLatestLogs,
  createWithPatternNetwork, generateDeviceCertificate, uninstallDeviceVPN,
  getDeviceToken
} from './devices';
import { getDeviceTypes, saveDeviceTypeData, removeDeviceTypeData } from './device_types';
import { getDefaultConfigurations, saveDefaultConfigurationData, removeDefaultConfigurationData } from './default_configurations';
import { getNetworkModels, saveNetworkModelData, removeNetworkModelData } from './network_models';
import { getPatternDevices, savePatternDeviceData, removePatternDeviceData } from './pattern_devices';
import { getDeviceImages, saveDeviceImageData, updateDeviceImageData, removeDeviceImageData } from './device_images';
import { getStatistics } from './home';

export function* rootSaga() {
  yield all([
    loginSaga(),
    getPermissions(),
    getProfile(),
    saveProfileSaga(),
    getGroups(),
    saveGroupData(),
    removeGroupData(),
    getCustomers(),
    saveCustomerData(),
    removeCustomerData(),
    getUsers(),
    saveUserData(),
    removeUserData(),
    getLocations(),
    saveLocationData(),
    removeLocationData(),
    getLocationLogs(),
    saveLocationLogData(),
    toggleLocationStatus(),
    getDevices(),
    saveDeviceData(),
    saveDeviceConfig(),
    generateDeviceCertificate(),
    uninstallDeviceVPN(),
    removeDeviceData(),
    getDeviceLatestLogs(),
    createWithPatternNetwork(),
    getDeviceTypes(),
    saveDeviceTypeData(),
    removeDeviceTypeData(),
    getDefaultConfigurations(),
    saveDefaultConfigurationData(),
    removeDefaultConfigurationData(),
    getNetworkModels(),
    saveNetworkModelData(),
    removeNetworkModelData(),
    getPatternDevices(),
    savePatternDeviceData(),
    removePatternDeviceData(),
    getStatistics(),
    getDeviceImages(),
    saveDeviceImageData(),
    updateDeviceImageData(),
    removeDeviceImageData(),
    getDeviceToken(),
  ]);
}

export default rootSaga;
