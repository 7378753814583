const defaultState = {
    pattern_network: {},
    ajaxLoading: false,
    ajaxLoaded: false,
    pattern_devices: [],
    treeData: [],
    showEditModal: false,
    showRemoveModal: false,
    page: 1,
    limit: 30,
    activeRow: {},
};

const pattern_devices = (state = defaultState, action) => {
    switch (action.type) {
        case 'PATTERN_DEVICES_SET_NETWORK_MODEL':
            return Object.assign({}, state, { pattern_network: action.payload });
        case 'PATTERN_DEVICES_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'PATTERN_DEVICES_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'PATTERN_DEVICES_SET_ACTIVE_ROW':
            return Object.assign({}, state, { activeRow: action.payload });
        case 'PATTERN_DEVICES_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'PATTERN_DEVICES_SHOW_EDIT_MODAL':
            return Object.assign({}, state, { showEditModal: action.payload });
        case 'PATTERN_DEVICES_SHOW_REMOVE_MODAL':
            return Object.assign({}, state, { showRemoveModal: action.payload });
        case 'PATTERN_DEVICES_FETCHING':
        case 'PATTERN_DEVICES_SAVING':
        case 'PATTERN_DEVICES_REMOVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'PATTERN_DEVICES_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ajaxLoaded: true, ...action.payload });
        case 'PATTERN_DEVICES_SAVED':
            return Object.assign({}, state, { ajaxLoading: false, showEditModal: false });
        case 'PATTERN_DEVICES_REMOVED':
            return Object.assign({}, state, { ajaxLoading: false, showRemoveModal: false });
        default:
            return state;
    }
};

export default pattern_devices;
