const defaultState = {
    email: '',
    password: '',
    loginError: '',
    ajaxLoading: false,
    permissionsLoaded: false,
    permissionFetchError: '',
    permissions: {},
    jwt_token: typeof (Storage) !== 'undefined' && sessionStorage.jwt_token ? sessionStorage.jwt_token : '',
    isAuthenticated: !!(typeof (Storage) !== 'undefined' && sessionStorage.jwt_token),
    showProfileModal: false,
    profile: {},
    userName: typeof (Storage) !== 'undefined' && localStorage.userName ? localStorage.userName : 'anonymous'
  };
  
  const auth = (state = defaultState, action) => {
    switch (action.type) {
      case 'LOGIN_FORM_EMAIL':
        return Object.assign({}, state, { email: action.payload });
      case 'LOGIN_FORM_PASSWORD':
        return Object.assign({}, state, { password: action.payload });
      case 'LOGIN_START_REQUEST':
        return Object.assign({}, state, { ajaxLoading: true });
      case 'LOGIN_FINISH_REQUEST':
        return Object.assign({}, state, { ajaxLoading: false });
      case 'LOGIN_SUCCESS':
        return Object.assign(
          {},
          state,
          {
            isAuthenticated: true,
            jwt_token: action.payload.token,
            userName: action.payload.userName,
            email: '',
            password: ''
          }
        );
      case 'PERMISSION_FETCHED':
        return Object.assign({}, state, {permissionsLoaded: true, permissions: action.payload});
      case 'PERRMISSION_FETCH_ERROR':
        return Object.assign({}, state, {permissionsLoaded: true, permissionFetchError: action.payload});
      case 'AUTH_LOGOUT':
        return Object.assign({}, state, {
          isAuthenticated: false,
          userName: '',
          permissionsLoaded: false,
          permissionFetchError: '',
          permissions: {},
        });
      case 'LOGIN_ERROR':
        return Object.assign({}, state, { loginError: action.payload });
      case 'LOGIN_ERROR_CLEAR':
        return Object.assign({}, state, { loginError: '' });
      case 'AUTH_SHOW_PROFILE_MODAL':
        return Object.assign({}, state, { showProfileModal: action.payload });
      case 'AUTH_UPDATE_PROFILE':
        const profile = Object.assign({}, state.profile, action.payload);
        return Object.assign({}, state, { profile });
      case 'AUTH_PROFILE_FETCHED':
        return Object.assign({}, state, { profile: action.payload });
      default:
        return state;
    }
  };
  
  export default auth;