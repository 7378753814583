export function fetchData({jwt = null, url, method = 'GET', data = null, is_json = true}){
    var options = {method: method, headers: {}};
    if(jwt){
        options.headers.authorization = 'Bearer ' + jwt;
    }
    if(data !== null && is_json === true){
        options.headers['content-type'] = 'application/json';
        options.body = JSON.stringify(data);
    }
    else if (data) {
        options.body = data;
    }

    return fetch(url, options)
    .then((response) => {
        if(response.ok) {
            return response.json();
        }

        throw new Error('Network response was not ok');
    })
    .then(result => (result))
    .catch((error) => {
        throw error;
    });
}