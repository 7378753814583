const defaultState = {
    location: {},
    ajaxLoading: false,
    ajaxLoaded: false,
    logs: [],
    treeData: [],
    page: 1,
    limit: 30,
    activeRow: {},
};

const logs = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOCATION_LOGS_SET_LOCATION':
            return Object.assign({}, state, { location: action.payload });
        case 'LOCATION_LOGS_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'LOCATION_LOGS_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'LOCATION_LOGS_SET_ACTIVE_ROW':
            return Object.assign({}, state, { activeRow: action.payload });
        case 'LOCATION_LOGS_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'LOCATION_LOGS_FETCHING':
        case 'LOCATION_LOGS_SAVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'LOCATION_LOGS_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ajaxLoaded: true, logs: action.payload });
        case 'LOCATION_LOGS_SAVED':
            return Object.assign({}, state, { ajaxLoading: false });
        default:
            return state;
    }
};

export default logs;
