import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializeGroup = () => {
    return {
        type: 'GROUPS_INITIALIZE',
    }
}

export const setPage = (page) => {
    return {
        type: 'GROUPS_SET_PAGE',
        payload: page
    }
}

export const setLimit = (limit) => {
    return {
        type: 'GROUPS_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'GROUPS_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'GROUPS_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'GROUPS_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'GROUPS_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = (data) => {
    return {
        type: 'GROUPS_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'GROUPS_REMOVE_DATA'
    }
}

export function* getGroups(){
    function* fetchGroupsData(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            yield put({type: 'GROUPS_FETCHING'});
            const groups = yield call(fetchData, {url: '/api/groups', method: 'GET', jwt: jwt});
            yield put({type: 'GROUPS_FETCHED', payload: groups});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin nhóm người dùng');
        }
    }

    yield takeLatest('GROUPS_INITIALIZE', fetchGroupsData);
}

export function* saveGroupData(){
    function* saveGroup(){
        const jwt = yield select(state => state.auth.jwt_token);
        const group = yield select(state => state.groups.activeRow);
        const url = group.id > 0 ? ('/api/group/'+group.id) : '/api/groups';
        try {
            yield put({type: 'GROUPS_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: group});
            yield put({type: 'GROUPS_SAVED'});
            toastr.success('Lưu thông tin nhóm người dùng thành công');
            yield put({type: 'GROUPS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin nhóm người dùng');
            yield put({type: 'GROUPS_SAVED'});
        }
    }

    yield takeLatest('GROUPS_SAVE_DATA', saveGroup);
}

export function* removeGroupData(){
    function* removeGroup(){
        const jwt = yield select(state => state.auth.jwt_token);
        const group = yield select(state => state.groups.activeRow);

        try {
            yield put({type: 'GROUPS_REMOVING'});
            yield call(fetchData, {url: '/api/group/'+group.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'GROUPS_REMOVED'});
            toastr.success('Xóa thông tin nhóm người dùng thành công');
            yield put({type: 'GROUPS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin nhóm người dùng');
            yield put({type: 'GROUPS_REMOVED'});
        }
    }

    yield takeLatest('GROUPS_REMOVE_DATA', removeGroup);
}
