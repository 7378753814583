import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializePatternDevice = () => {
    return {
        type: 'PATTERN_DEVICES_INITIALIZE'
    }
}

export const setPage = (page) => {
    return {
        type: 'PATTERN_DEVICES_SET_PAGE',
        payload: page
    }
}

export const setNetworkModel = (pattern_network) => {
    return {
        type: 'PATTERN_DEVICES_SET_NETWORK_MODEL',
        payload: pattern_network
    }
}

export const setLimit = (limit) => {
    return {
        type: 'PATTERN_DEVICES_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'PATTERN_DEVICES_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'PATTERN_DEVICES_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'PATTERN_DEVICES_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'PATTERN_DEVICES_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = () => {
    return {
        type: 'PATTERN_DEVICES_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'PATTERN_DEVICES_REMOVE_DATA'
    }
}

export function* getPatternDevices(){
    function* fetchPatternDevicesData(){
        const {jwt, pattern_network} = yield select(state => ({jwt: state.auth.jwt_token, pattern_network: state.pattern_devices.pattern_network}));
        if(pattern_network.id > 0){
            try {
                yield put({type: 'PATTERN_DEVICES_FETCHING'});
                const devices = yield call(fetchData, {url: '/api/pattern-network/'+pattern_network.id+'/devices', method: 'GET', jwt: jwt});
                yield put({type: 'PATTERN_DEVICES_FETCHED', payload: devices});
            } catch (error){
                toastr.error('Có lỗi xảy ra khi lấy thông tin thiết bị');
            }
        } else {
            toastr.info('Vui lòng chọn mô hình mẫu trước');
        }
    }

    yield takeLatest('PATTERN_DEVICES_INITIALIZE', fetchPatternDevicesData);
}

export function* savePatternDeviceData(){
    function* savePatternDevice(){
        const {jwt, pattern_network} = yield select(state => ({jwt: state.auth.jwt_token, pattern_network: state.pattern_devices.pattern_network}));
        let device = yield select(state => state.pattern_devices.activeRow);
        if(pattern_network.id > 0){
            device['pattern_network_id'] = pattern_network.id;
            let url = device.id > 0 ? ('/api/pattern-network/'+pattern_network.id+'/devices/'+device.id) : '/api/pattern-network/'+pattern_network.id+'/devices';
            try {
                yield put({type: 'PATTERN_DEVICES_SAVING'});
                yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: device});
                yield put({type: 'PATTERN_DEVICES_SAVED'});
                toastr.success('Lưu thông tin thiết bị thành công');
                yield put({type: 'PATTERN_DEVICES_SET_ACTIVE_ROW', payload: {}});
                yield put({type: 'PATTERN_DEVICES_INITIALIZE'});
            } catch (error){
                toastr.error('Có lỗi xảy ra khi lưu thông tin thiết bị');
                yield put({type: 'PATTERN_DEVICES_SAVED'});
            }
        } else {
            toastr.info('Vui lòng chọn mô hình mẫu trước');
        }
    }

    yield takeLatest('PATTERN_DEVICES_SAVE_DATA', savePatternDevice);
}

export function* removePatternDeviceData(){
    function* removeDevice(){
        const {jwt, pattern_network, device} = yield select(state => ({
            jwt: state.auth.jwt_token,
            pattern_network: state.pattern_devices.pattern_network,
            device: state.pattern_devices.activeRow
        }));
        const url = '/api/pattern-network/'+pattern_network.id+'/devices/'+device.id;

        if(pattern_network.id > 0){
            try {
                yield put({type: 'PATTERN_DEVICES_REMOVING'});
                yield call(fetchData, {url: url, method: 'DELETE', jwt: jwt});
                yield put({type: 'PATTERN_DEVICES_REMOVED'});
                toastr.success('Xóa thông tin thiết bị thành công');
                yield put({type: 'PATTERN_DEVICES_INITIALIZE'});
            } catch (error){
                toastr.error('Có lỗi xảy ra khi xóa thông tin thiết bị');
                yield put({type: 'PATTERN_DEVICES_REMOVED'});
            }
        } else {
            toastr.info('Vui lòng chọn mô hình mẫu trước');
        }
    }

    yield takeLatest('PATTERN_DEVICES_REMOVE_DATA', removeDevice);
}