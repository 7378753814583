import { combineReducers } from 'redux';
import {reducer as toastr} from 'react-redux-toastr'

import auth from './auth';
import nav from './nav';
import users from './users';
import groups from './groups';
import customers from './customers';
import locations from './locations';
import location_logs from './location_logs';
import devices from './devices';
import device_types from './device_types';
import default_configurations from './default_configurations';
import network_models from './network_models';
import pattern_devices from './pattern_devices';
import device_images from './device_images';
import home from './home';

const rootReducer = combineReducers({
  toastr,
  auth,
  nav,
  users,
  groups,
  customers,
  locations,
  location_logs,
  devices,
  device_types,
  default_configurations,
  network_models,
  pattern_devices,
  device_images,
  home,
});

export default rootReducer;
