const defaultState = {
    ajaxLoading: false,
    ajaxLoaded: false,
    device_types: [],
    page: 1,
    limit: 30,
    showEditModal: false,
    showRemoveModal: false,
    activeRow: {},
};

const device_types = (state = defaultState, action) => {
    switch (action.type) {
        case 'DEVICE_TYPES_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'DEVICE_TYPES_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'DEVICE_TYPES_SHOW_EDIT_MODAL':
            return Object.assign({}, state, { showEditModal: action.payload });
        case 'DEVICE_TYPES_SHOW_REMOVE_MODAL':
            return Object.assign({}, state, { showRemoveModal: action.payload });
        case 'DEVICE_TYPES_SET_ACTIVE_ROW':
            return Object.assign({}, state, { activeRow: action.payload });
        case 'DEVICE_TYPES_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'DEVICE_TYPES_FETCHING':
        case 'DEVICE_TYPES_SAVING':
        case 'DEVICE_TYPES_REMOVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'DEVICE_TYPES_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ajaxLoaded: true, device_types: action.payload });
        case 'DEVICE_TYPES_SAVED':
            return Object.assign({}, state, { ajaxLoading: false, showEditModal: false });
        case 'DEVICE_TYPES_REMOVED':
            return Object.assign({}, state, { ajaxLoading: false, showRemoveModal: false });
        default:
            return state;
    }
};

export default device_types;
