import { put, call, takeLatest, select } from 'redux-saga/effects';
import { fetchData } from './fetch';
import {toastr} from 'react-redux-toastr'

export const initializeAuth = () => {
  return {
    type: 'AUTH_INITIALIZE'
  };
};

export const initializeProfile = () => {
  return {
    type: 'AUTH_INITIALIZE_PROFILE'
  };
};

export const showProfileModal = (show) => {
  return {
    type: 'AUTH_SHOW_PROFILE_MODAL',
    payload: show
  }
}

export const updateProfile = (data) => {
  return {
      type: 'AUTH_UPDATE_PROFILE',
      payload: data
  }
}

export const saveProfile = () => {
  return {
    type: 'AUTH_PROFILE_SAVE'
  }
}

export const changeEmail = (email) => {
  return {
    type: 'LOGIN_FORM_EMAIL',
    payload: email
  };
};

export const changePassword = (password) => {
  return {
    type: 'LOGIN_FORM_PASSWORD',
    payload: password
  };
};

export const onLoginClick = () => {
  return {
    type: 'DO_LOGIN_REQUEST'
  };
};

export const onLoginClearError = () => {
  return {
    type: 'LOGIN_ERROR_CLEAR'
  };
};

function* doAjaxLogin() {
  const data = yield select((state) => {
    return {
      email: state.auth.email,
      password: state.auth.password
    };
  });

  try {
    yield put({ type: 'LOGIN_START_REQUEST' });
    const userInfo = yield call(fetchData, {url: '/api/login', method: 'POST', data: data});

    if (userInfo.token) {
      if (typeof (Storage) !== 'undefined') {
        sessionStorage.jwt_token = userInfo.token;
        localStorage.userName = userInfo.userName;
      } else {
        yield put({ type: 'LOGIN_ERROR', payload: 'You may use an old version of browser, please update to the latest version' });
      }
      yield put({ type: 'LOGIN_SUCCESS', payload: { token: userInfo.token, userName: userInfo.userName } });
      yield put({ type: 'SYNC_USER_ITEMS' });
    } else {
      yield put({ type: 'LOGIN_ERROR', payload: userInfo.error_message ? userInfo.error_message : 'Login failed' });
    }
  } catch (error) {
    yield put({ type: 'LOGIN_ERROR', payload: 'There is an error while login to server' });
  }

  yield put({ type: 'LOGIN_FINISH_REQUEST' });
}

export function* loginSaga() {
  yield takeLatest('DO_LOGIN_REQUEST', doAjaxLogin);
}

export function* getPermissions(){
  function* requestPermissions(){
    const jwt = yield select(state => state.auth.jwt_token);
    try {
      const permissions = yield call(fetchData, {url: '/api/permissions', method: 'GET', jwt: jwt});
      yield put({type: 'PERMISSION_FETCHED', payload: permissions});
    } catch (error){
      yield put({type: 'PERMISSION_FETCH_ERROR', payload: 'There is an error while loading user permissions'});
    }
  }

  yield takeLatest('AUTH_INITIALIZE', requestPermissions);
}

export function* getProfile(){
  function* requestProfile(){
    const jwt = yield select(state => state.auth.jwt_token);
    try {
      const profile = yield call(fetchData, {url: '/api/profile', method: 'GET', jwt: jwt});
      yield put({type: 'AUTH_PROFILE_FETCHED', payload: profile});
    } catch (error){
      yield put({type: 'AUTH_PROFILE_ERROR', payload: 'There is an error while loading user profile'});
    }
  }

  yield takeLatest('AUTH_INITIALIZE_PROFILE', requestProfile);
}

export function* saveProfileSaga(){
  function* ajaxSaveProfile(){
    const {jwt, profile} = yield select(state => ({jwt: state.auth.jwt_token, profile: state.auth.profile}));
    try {
      yield call(fetchData, {url: '/api/profile', method: 'POST', jwt: jwt, data: profile});
      toastr.success('Lưu thông tin thành công');
    } catch (error){
      console.error(error);
      toastr.error('Lưu thông tin bị lỗi');
    }
  }

  yield takeLatest('AUTH_PROFILE_SAVE', ajaxSaveProfile);
}
