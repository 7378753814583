import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loading from 'views/elements/Loading';
import NavBar from './NavBar';
import Modal from 'views/elements/Modal';
import ProfileForm from 'views/containers/ProfileForm';
import * as actions from 'actions/auth';

require('bulma/css/bulma.css');
require('font-awesome/css/font-awesome.min.css');
require('./main.css');

class MainLayout extends React.Component {
  constructor(props){
    super(props);

    const { dispatch } = props;
    this.actions = bindActionCreators(actions, dispatch);
  }

  componentDidMount(){
    if(!this.props.permissionsLoaded){
      this.actions.initializeAuth();
    }
  }

  render() {
    if(!this.props.permissionsLoaded){
      return <Loading />
    }

    return (
      <div>
          <div className="container">
              <NavBar />
          </div>
          <section className="section">
              <div className="container">{this.props.children}</div>
          </section>
          {this.props.showProfileModal &&
          (<Modal
            buttons={[
              {title: "Lưu lại", className: "button is-success", onClick: () => this.actions.saveProfile()},
              {title: "Đóng lại", className: "button is-warning", onClick: () => this.actions.showProfileModal(false)},
            ]}
            showModal={this.props.showProfileModal}
            modalTitle="Sửa thông tin cá nhân"
            onCloseModal={() => this.actions.showProfileModal(false)}
            ModalContent={ProfileForm} />)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissionsLoaded: state.auth.permissionsLoaded,
    showProfileModal: state.auth.showProfileModal,
  };
};

export default connect(mapStateToProps)(MainLayout);