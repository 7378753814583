import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializeNetworkModel = () => {
    return {
        type: 'NETWORK_MODELS_INITIALIZE',
    }
}

export const setPage = (page) => {
    return {
        type: 'NETWORK_MODELS_SET_PAGE',
        payload: page
    }
}

export const setLimit = (limit) => {
    return {
        type: 'NETWORK_MODELS_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'NETWORK_MODELS_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'NETWORK_MODELS_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const showDevicesTable = (show) => {
    return {
        type: 'NETWORK_MODELS_SHOW_DEVICES_TABLE',
        payload: show
    }
}

export const toggleDevicesTable = () => {
    return {
        type: 'NETWORK_MODELS_TOGGLE_DEVICES_TABLE'
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'NETWORK_MODELS_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'NETWORK_MODELS_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = (data) => {
    return {
        type: 'NETWORK_MODELS_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'NETWORK_MODELS_REMOVE_DATA'
    }
}

export function* getNetworkModels(){
    function* fetchNetworkModelsData(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            yield put({type: 'NETWORK_MODELS_FETCHING'});
            const network_models = yield call(fetchData, {url: '/api/pattern-networks', method: 'GET', jwt: jwt});
            yield put({type: 'NETWORK_MODELS_FETCHED', payload: network_models});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin mẫu mô hình mạng');
        }
    }

    yield takeLatest('NETWORK_MODELS_INITIALIZE', fetchNetworkModelsData);
}

export function* saveNetworkModelData(){
    function* saveNetworkModel(){
        const jwt = yield select(state => state.auth.jwt_token);
        const network_model = yield select(state => state.network_models.activeRow);
        const url = network_model.id > 0 ? ('/api/pattern-network/'+network_model.id) : '/api/pattern-networks';
        try {
            yield put({type: 'NETWORK_MODELS_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: network_model});
            yield put({type: 'NETWORK_MODELS_SAVED'});
            toastr.success('Lưu thông tin mẫu mô hình mạng thành công');
            yield put({type: 'NETWORK_MODELS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin mẫu mô hình mạng');
            yield put({type: 'NETWORK_MODELS_SAVED'});
        }
    }

    yield takeLatest('NETWORK_MODELS_SAVE_DATA', saveNetworkModel);
}

export function* removeNetworkModelData(){
    function* removeNetworkModel(){
        const jwt = yield select(state => state.auth.jwt_token);
        const network_model = yield select(state => state.network_models.activeRow);

        try {
            yield put({type: 'NETWORK_MODELS_REMOVING'});
            yield call(fetchData, {url: '/api/pattern-network/'+network_model.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'NETWORK_MODELS_REMOVED'});
            toastr.success('Xóa thông tin mẫu mô hình mạng thành công');
            yield put({type: 'NETWORK_MODELS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin mẫu mô hình mạng');
            yield put({type: 'NETWORK_MODELS_REMOVED'});
        }
    }

    yield takeLatest('NETWORK_MODELS_REMOVE_DATA', removeNetworkModel);
}
