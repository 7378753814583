import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const setActiveTab = (tabIndex) => {
    return {
        type: 'USERS_ACTIVE_TAB',
        payload: tabIndex,
    };
};

export const initializeUser = () => {
    return {
        type: 'USERS_INITIALIZE',
    }
}

export const setPage = (page) => {
    return {
        type: 'USERS_SET_PAGE',
        payload: page
    }
}

export const setLimit = (limit) => {
    return {
        type: 'USERS_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'USERS_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'USERS_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'USERS_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'USERS_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = (data) => {
    return {
        type: 'USERS_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'USERS_REMOVE_DATA'
    }
}

export function* getUsers(){
    function* fetchUsersData(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            yield put({type: 'USERS_FETCHING'});
            const users = yield call(fetchData, {url: '/api/users', method: 'GET', jwt: jwt});
            yield put({type: 'USERS_FETCHED', payload: users});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin user');
        }
    }

    yield takeLatest('USERS_INITIALIZE', fetchUsersData);
}

export function* saveUserData(){
    function* saveUser(){
        const jwt = yield select(state => state.auth.jwt_token);
        const user = yield select(state => state.users.activeRow);
        const url = user.id > 0 ? ('/api/user/'+user.id) : '/api/users';
        try {
            yield put({type: 'USERS_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: user});
            yield put({type: 'USERS_SAVED'});
            toastr.success('Lưu thông tin user thành công');
            yield put({type: 'USERS_INITIALIZE'});
        } catch (error){
            yield put({type: 'USERS_SAVED'});
            toastr.error('Có lỗi xảy ra khi lưu thông tin user');
        }
    }

    yield takeLatest('USERS_SAVE_DATA', saveUser);
}

export function* removeUserData(){
    function* removeUser(){
        const jwt = yield select(state => state.auth.jwt_token);
        const user = yield select(state => state.users.activeRow);

        try {
            yield put({type: 'USERS_REMOVING'});
            yield call(fetchData, {url: '/api/user/'+user.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'USERS_REMOVED'});
            toastr.success('Xóa thông tin user thành công');
            yield put({type: 'USERS_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin user');
            yield put({type: 'USERS_REMOVED'});
        }
    }

    yield takeLatest('USERS_REMOVE_DATA', removeUser);
}
