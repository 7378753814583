import get from 'lodash.get';
import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const setActiveTab = (tabIndex) => {
    return {
        type: 'DEVICES_ACTIVE_TAB',
        payload: tabIndex,
    };
};

export const initializeDevice = (location_id) => {
    return {
        type: 'DEVICES_INITIALIZE',
        payload: location_id
    }
}

export const loadDeviceLatestLogs = () => {
    return {
        type: 'DEVICE_LOGS_INITIALIZE'
    }
}

export const setPage = (page) => {
    return {
        type: 'DEVICES_SET_PAGE',
        payload: page
    }
}

export const setLimit = (limit) => {
    return {
        type: 'DEVICES_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'DEVICES_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showSettingModal = (show) => {
    return {
        type: 'DEVICES_SHOW_SETTING_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'DEVICES_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const showUploadModal = (show) => {
    return {
        type: 'DEVICES_SHOW_UPLOAD_MODAL',
        payload: show
    }
}

export const showLogModal = (show) => {
    return {
        type: 'DEVICES_SHOW_LOG_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'DEVICES_SET_ACTIVE_ROW',
        payload: row
    }
}

export const setNetworkModel = (pattern_network_id) => {
    return {
        type: 'DEVICES_SET_NETWORK_MODEL',
        payload: pattern_network_id
    }
}

export const setFilter = (filter) => {
    return {
        type: 'DEVICES_SET_FILTER',
        payload: filter
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'DEVICES_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = () => {
    return {
        type: 'DEVICES_SAVE_DATA'
    }
}

export const saveConfig = () => {
    return {
        type: 'DEVICES_SAVE_CONFIG'
    }
}

export const removeActiveRow = () => {
    return {
        type: 'DEVICES_REMOVE_DATA'
    }
}

export const setupDeviceWithPattern = () => {
    return {
        type: 'DEVICES_SETUP_WITH_PATTERN'
    }
}

export const generateCertificate = () => {
    return {
        type: 'DEVICES_GENERATE_CERTIFICATE'
    }
}

export const uninstallVPN = () => {
    return {
        type: 'DEVICES_UNINSTALL_VPN'
    }
}

export function* getDevices(){
    function* fetchDevicesData(){
        const {jwt, location_id} = yield select(state => ({jwt: state.auth.jwt_token, location_id: state.devices.filters.location_id}));
        if(location_id > 0){
            try {
                yield put({type: 'DEVICES_FETCHING'});
                const devices = yield call(fetchData, {url: '/api/devices?location_id='+location_id, method: 'GET', jwt: jwt});
                yield put({type: 'DEVICES_FETCHED', payload: devices});
            } catch (error){
                toastr.error('Có lỗi xảy ra khi lấy thông tin thiết bị');
            }
        } else {
            toastr.info('Vui lòng chọn địa điểm trước');
        }
    }

    yield takeLatest('DEVICES_INITIALIZE', fetchDevicesData);
}

export function* getDeviceLatestLogs(){
    function* fetchDeviceLatestLogsData(){
        const {jwt, device} = yield select(state => ({jwt: state.auth.jwt_token, device: state.devices.activeRow}));
        if(device.id > 0){
            try {
                yield put({type: 'DEVICE_LOGS_FETCHING'});
                const log = yield call(fetchData, {url: '/api/device/'+device.id+'/latest_logs', method: 'GET', jwt: jwt});
                yield put({type: 'DEVICE_LOGS_FETCHED', payload: log});
            } catch (error){
                toastr.error('Có lỗi xảy ra khi lấy thông tin log thiết bị');
            }
        }
    }

    yield takeLatest('DEVICE_LOGS_INITIALIZE', fetchDeviceLatestLogsData);
}

function* saveDevice(saveConfig = false){
    const {jwt, location_id, configuration_types} = yield select(state => ({
        jwt: state.auth.jwt_token,
        location_id: state.devices.filters.location_id,
        configuration_types: state.default_configurations.configuration_types
    }));
    let device = yield select(state => state.devices.activeRow);
    const config = get(configuration_types, device.configuration_type, configuration_types.TPLink);
    let errors = false;
    if(saveConfig && !config.single_config){
        const validations = get(config, 'validations', {});
        let device_config = JSON.parse(device.config ? device.config : device.default_config);
        for(let config_name in validations){
            const config_data = get(device_config, config_name, '');
            for(let validation_type in validations[config_name]){
                if(validation_type === 'min_length'){
                    if(config_data.length > 0 && config_data.length < validations[config_name][validation_type]){
                        toastr.error(config_name+' phải có ít nhất ' + validations[config_name][validation_type] + ' kí tự');
                        errors = true;
                    }
                }
            }
        }
    }
    if(errors) return false;

    if(location_id > 0){
        device['location_id'] = location_id;
        let url = device.id > 0 ? ('/api/device/'+device.id) : '/api/devices';
        if(saveConfig === true){
            url += '/config';
        }
        try {
            yield put({type: 'DEVICES_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: device});
            yield put({type: 'DEVICES_SAVED', payload: true});
            toastr.success('Lưu thông tin device thành công');
            yield put({type: 'DEVICES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin thiết bị');
            yield put({type: 'DEVICES_SAVED', payload: false});
        }
    } else {
        toastr.info('Vui lòng chọn địa điểm trước');
    }
}

export function* saveDeviceData(){
    yield takeLatest('DEVICES_SAVE_DATA', saveDevice, false);
}

export function* saveDeviceConfig(){
    yield takeLatest('DEVICES_SAVE_CONFIG', saveDevice, true);
}

export function getToken(mac){
    return {
        type: 'DEVICES_TOKEN_DATA',
        payload: mac
    }
}

export function* removeDeviceData(){
    function* removeDevice(){
        const jwt = yield select(state => state.auth.jwt_token);
        const device = yield select(state => state.devices.activeRow);

        try {
            yield put({type: 'DEVICES_REMOVING'});
            yield call(fetchData, {url: '/api/device/'+device.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'DEVICES_REMOVED'});
            toastr.success('Xóa thông tin thiết bị thành công');
            yield put({type: 'DEVICES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin thiết bị');
            yield put({type: 'DEVICES_REMOVED'});
        }
    }

    yield takeLatest('DEVICES_REMOVE_DATA', removeDevice);
}

export function* getDeviceToken(){
    function* callAPI(mac){
        const jwt = yield select(state => state.auth.jwt_token);
        const device = yield select(state => state.devices.activeRow);

        try {
            const res = yield call(fetchData, {url: '/api/device/token/'+device.id+'?mac='+encodeURIComponent(mac), method: 'GET', jwt: jwt});
            yield put({type: 'DEVICES_SET_ROW_VALUE', payload: {token: res.token}});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin token');
        }
    }

    yield takeLatest('DEVICES_TOKEN_DATA', (action) => callAPI(action.payload));
}

export function* createWithPatternNetwork(){
    function* setupWithPattern(){
        const {jwt, pattern_network_id, location_id} = yield select(state => ({
            jwt: state.auth.jwt_token,
            pattern_network_id: state.devices.pattern_network_id,
            location_id: state.devices.filters.location_id
        }));

        const url = '/api/location/'+location_id+'/devices';
        try{
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: {pattern: pattern_network_id}});
            toastr.success('Tạo thiết bị từ mô hình mẫu thành công');
            yield put({type: 'DEVICES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi tạo thiết bị từ mô hình mẫu');
        }
    }

    yield takeLatest('DEVICES_SETUP_WITH_PATTERN', setupWithPattern);
}

export function* generateDeviceCertificate(){
    function* callAPI(){
        const {jwt, device_id} = yield select(state => ({
            jwt: state.auth.jwt_token,
            device_id: state.devices.activeRow.id
        }));

        const url = '/api/device/'+device_id+'/certificate';
        try{
            yield put({type: 'DEVICE_VPN_RUNNING', payload: true});
            const res = yield call(fetchData, {url: url, method: 'POST', jwt: jwt});
            setActiveRow(res.device);
            yield put({type: 'DEVICE_VPN_RUNNING', payload: false});
            toastr.success('Tạo certificate thành công');
        } catch (error){
            console.log(error);
            toastr.error('Có lỗi xảy ra khi tạo certificate');
            yield put({type: 'DEVICE_VPN_RUNNING', payload: false});
        }
    }

    yield takeLatest('DEVICES_GENERATE_CERTIFICATE', callAPI);
}

export function* uninstallDeviceVPN(){
    function* callAPI(){
        const {jwt, device_id} = yield select(state => ({
            jwt: state.auth.jwt_token,
            device_id: state.devices.activeRow.id
        }));

        const url = '/api/device/'+device_id+'/uninstall_vpn';
        try{
            yield put({type: 'DEVICE_VPN_RUNNING', payload: true});
            const res = yield call(fetchData, {url: url, method: 'POST', jwt: jwt});
            setActiveRow(res.device);
            yield put({type: 'DEVICE_VPN_RUNNING', payload: false});
            toastr.success('Tạo uninstall VPN config thành công');
        } catch (error){
            console.log(error);
            toastr.error('Có lỗi xảy ra khi tạo uninstall VPN config');
            yield put({type: 'DEVICE_VPN_RUNNING', payload: false});
        }
    }

    yield takeLatest('DEVICES_UNINSTALL_VPN', callAPI);
}
