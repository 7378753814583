import React from 'react';

const Modal = ({showModal=false, ModalContent, modalTitle, onCloseModal, buttons=[], isLargeModal=true}) => {
    const className = showModal ? "modal is-active" : "modal";
    let footer = null;
    let buttonsElement = null;
    if(buttons.length > 0){
        buttonsElement = buttons.map((button, index) => {
            return (<button disabled={button.disabled} key={index} className={button.className} onClick={button.onClick}>{button.title}</button>)
        });
        footer = (<footer className="modal-card-foot">{buttonsElement}</footer>);
    }
    return (
        <div className={className}>
            <div className="modal-background"></div>
            <div className={"modal-card" + (isLargeModal ? ' is-large-modal' : '')}>
                <header className="modal-card-head">
                    <p className="modal-card-title">{modalTitle}</p>
                    <button className="delete" onClick={onCloseModal} aria-label="close"></button>
                </header>
                <section className="modal-card-body">
                    {ModalContent ? <ModalContent /> : null}
                </section>
                {footer}
            </div>
        </div>
    );
}

export default Modal;
