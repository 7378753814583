const defaultState = {
    ajaxLoading: false,
    devices: 0,
    error_devices: 0,
    locations: 0,
    error_locations: 0
}

const home = (state = defaultState, action) => {
    switch (action.type) {
        case 'HOME_STATISTICS_FETCHING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'HOME_STATISTICS_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ...action.payload });
        default:
            return state;
    }
};

export default home;
