import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr';

import { fetchData } from './fetch';

export const initializeStatistics = () => {
    return {
      type: 'HOME_STATISTICS_INITIALIZE'
    };
};

export function* getStatistics(){
    function* requestStatistics(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            const statistics = yield call(fetchData, {url: '/api/statistics', method: 'GET', jwt: jwt});
            yield put({type: 'HOME_STATISTICS_FETCHED', payload: statistics});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin');
        }
    }
  
    yield takeLatest('HOME_STATISTICS_INITIALIZE', requestStatistics);
}
