import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import ReduxToastr from 'react-redux-toastr'

import { rootSaga } from './actions/sagas';
import Routes from './routes';
import rootReducer from './reducers';

require('react-redux-toastr/lib/css/react-redux-toastr.min.css');

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// then run the sagas
sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <ReduxToastr />
        <Routes />
    </Provider>,
    document.getElementById('root')
);
