import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializeDeviceType = () => {
    return {
        type: 'DEVICE_TYPES_INITIALIZE',
    }
}

export const setPage = (page) => {
    return {
        type: 'DEVICE_TYPES_SET_PAGE',
        payload: page
    }
}

export const setLimit = (limit) => {
    return {
        type: 'DEVICE_TYPES_SET_LIMIT',
        payload: limit
    }
}

export const showEditModal = (show) => {
    return {
        type: 'DEVICE_TYPES_SHOW_EDIT_MODAL',
        payload: show
    }
}

export const showRemoveModal = (show) => {
    return {
        type: 'DEVICE_TYPES_SHOW_REMOVE_MODAL',
        payload: show
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'DEVICE_TYPES_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'DEVICE_TYPES_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = (data) => {
    return {
        type: 'DEVICE_TYPES_SAVE_DATA'
    }
}

export const removeActiveRow = (data) => {
    return {
        type: 'DEVICE_TYPES_REMOVE_DATA'
    }
}

export function* getDeviceTypes(){
    function* fetchDeviceTypesData(){
        const jwt = yield select(state => state.auth.jwt_token);
        try {
            yield put({type: 'DEVICE_TYPES_FETCHING'});
            const device_types = yield call(fetchData, {url: '/api/device-types', method: 'GET', jwt: jwt});
            yield put({type: 'DEVICE_TYPES_FETCHED', payload: device_types});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lấy thông tin loại thiết bị');
        }
    }

    yield takeLatest('DEVICE_TYPES_INITIALIZE', fetchDeviceTypesData);
}

export function* saveDeviceTypeData(){
    function* saveDeviceType(){
        const jwt = yield select(state => state.auth.jwt_token);
        const device_type = yield select(state => state.device_types.activeRow);
        const url = device_type.id > 0 ? ('/api/device-type/'+device_type.id) : '/api/device-types';
        try {
            yield put({type: 'DEVICE_TYPES_SAVING'});
            yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: device_type});
            yield put({type: 'DEVICE_TYPES_SAVED'});
            toastr.success('Lưu thông tin loại thiết bị thành công');
            yield put({type: 'DEVICE_TYPES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi lưu thông tin loại thiết bị');
            yield put({type: 'DEVICE_TYPES_SAVED'});
        }
    }

    yield takeLatest('DEVICE_TYPES_SAVE_DATA', saveDeviceType);
}

export function* removeDeviceTypeData(){
    function* removeDeviceType(){
        const jwt = yield select(state => state.auth.jwt_token);
        const device_type = yield select(state => state.device_types.activeRow);

        try {
            yield put({type: 'DEVICE_TYPES_REMOVING'});
            yield call(fetchData, {url: '/api/device-type/'+device_type.id, method: 'DELETE', jwt: jwt});
            yield put({type: 'DEVICE_TYPES_REMOVED'});
            toastr.success('Xóa thông tin loại thiết bị thành công');
            yield put({type: 'DEVICE_TYPES_INITIALIZE'});
        } catch (error){
            toastr.error('Có lỗi xảy ra khi xóa thông tin loại thiết bị');
            yield put({type: 'DEVICE_TYPES_REMOVED'});
        }
    }

    yield takeLatest('DEVICE_TYPES_REMOVE_DATA', removeDeviceType);
}
