const defaultState = {
    tabItems: [
        {title: "Users", key: "users"},
        {title: "Nhóm", key: "groups"},
    ],
    activeTab: 0,
    ajaxLoading: false,
    ajaxLoaded: false,
    users: [],
    page: 1,
    limit: 30,
    showEditModal: false,
    showRemoveModal: false,
    activeRow: {},
};

const users = (state = defaultState, action) => {
    switch (action.type) {
        case 'USERS_ACTIVE_TAB':
            return Object.assign({}, state, { activeTab: action.payload });
        case 'USERS_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'USERS_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'USERS_SHOW_EDIT_MODAL':
            return Object.assign({}, state, { showEditModal: action.payload });
        case 'USERS_SHOW_REMOVE_MODAL':
            return Object.assign({}, state, { showRemoveModal: action.payload });
        case 'USERS_SET_ACTIVE_ROW':
            state = Object.assign({}, state, { activeRow: action.payload });
            return state
        case 'USERS_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'USERS_FETCHING':
        case 'USERS_SAVING':
        case 'USERS_REMOVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'USERS_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ajaxLoaded: true, users: action.payload });
        case 'USERS_SAVED':
            return Object.assign({}, state, { ajaxLoading: false, showEditModal: false });
        case 'USERS_REMOVED':
            return Object.assign({}, state, { ajaxLoading: false, showRemoveModal: false });
        default:
            return state;
    }
};

export default users;
