import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import routes from './config';
import NoRouteMatch from 'views/NoRouteMatch';

class Routes extends React.Component {
    render(){
        return (<Router>
            <Switch>
                {routes.map((route) => {
                    const { layout: Layout, component: Component, loginRequired } = route;
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => {
                                document.title = route.title || 'Welcome';
                                const queryParams = queryString.parse(props.location.search);
                                if (loginRequired === false || this.props.isAuthenticated) {
                                    return (<Layout title={route.title}><Component queryParams={queryParams} {...props} /></Layout>);
                                }
                                return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
                            }}
                        />
                    );
                })}
                <Route path="*">
                    <NoRouteMatch />
                </Route>
            </Switch>
        </Router>);
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};
  
export default connect(mapStateToProps)(Routes);
