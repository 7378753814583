import { select, call, put, takeLatest } from "redux-saga/effects";
import {toastr} from 'react-redux-toastr'

import { fetchData } from './fetch';

export const initializeLocationLog = () => {
    return {
        type: 'LOCATION_LOGS_INITIALIZE'
    }
}

export const setPage = (page) => {
    return {
        type: 'LOCATION_LOGS_SET_PAGE',
        payload: page
    }
}

export const setLocation = (location) => {
    return {
        type: 'LOCATION_LOGS_SET_LOCATION',
        payload: location
    }
}

export const setLimit = (limit) => {
    return {
        type: 'LOCATION_LOGS_SET_LIMIT',
        payload: limit
    }
}

export const setActiveRow = (row) => {
    return {
        type: 'LOCATION_LOGS_SET_ACTIVE_ROW',
        payload: row
    }
}

export const updateActiveRow = (data) => {
    return {
        type: 'LOCATION_LOGS_SET_ROW_VALUE',
        payload: data
    }
}

export const saveActiveRow = () => {
    return {
        type: 'LOCATION_LOGS_SAVE_DATA'
    }
}

export function* getLocationLogs(){
    function* fetchLocationLogsData(){
        const {jwt, location} = yield select(state => ({jwt: state.auth.jwt_token, location: state.location_logs.location}));
        if(location.id > 0){
            try {
                yield put({type: 'LOCATION_LOGS_FETCHING'});
                const logs = yield call(fetchData, {url: '/api/location/'+location.id+'/logs', method: 'GET', jwt: jwt});
                yield put({type: 'LOCATION_LOGS_FETCHED', payload: logs});
            } catch (error){
                toastr.error('Có lỗi xảy ra khi lấy thông tin log');
            }
        } else {
            toastr.info('Vui lòng chọn địa điểm trước');
        }
    }

    yield takeLatest('LOCATION_LOGS_INITIALIZE', fetchLocationLogsData);
}

export function* saveLocationLogData(){
    function* saveLocationLog(saveConfig = false){
        const {jwt, location} = yield select(state => ({jwt: state.auth.jwt_token, location: state.location_logs.location}));
        let log = yield select(state => state.location_logs.activeRow);
        if(location.id > 0){
            log['location_id'] = location.id;
            let url = log.id > 0 ? ('/api/location/'+location.id+'/logs/'+log.id) : '/api/location/'+location.id+'/logs';
            try {
                yield put({type: 'LOCATION_LOGS_SAVING'});
                yield call(fetchData, {url: url, method: 'POST', jwt: jwt, data: log});
                yield put({type: 'LOCATION_LOGS_SAVED'});
                toastr.success('Lưu thông tin log thành công');
                yield put({type: 'LOCATION_LOGS_SET_ACTIVE_ROW', payload: {}});
                yield put({type: 'LOCATION_LOGS_INITIALIZE'});
            } catch (error){
                toastr.error('Có lỗi xảy ra khi lưu thông tin log');
                yield put({type: 'LOCATION_LOGS_SAVED'});
            }
        } else {
            toastr.info('Vui lòng chọn địa điểm trước');
        }
    }

    yield takeLatest('LOCATION_LOGS_SAVE_DATA', saveLocationLog);
}