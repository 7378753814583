const defaultState = {
    ajaxLoading: false,
    ajaxLoaded: false,
    groups: [],
    page: 1,
    limit: 30,
    showEditModal: false,
    showRemoveModal: false,
    activeRow: {},
};

const groups = (state = defaultState, action) => {
    switch (action.type) {
        case 'GROUPS_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'GROUPS_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'GROUPS_SHOW_EDIT_MODAL':
            return Object.assign({}, state, { showEditModal: action.payload });
        case 'GROUPS_SHOW_REMOVE_MODAL':
            return Object.assign({}, state, { showRemoveModal: action.payload });
        case 'GROUPS_SET_ACTIVE_ROW':
            return Object.assign({}, state, { activeRow: action.payload });
        case 'GROUPS_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'GROUPS_FETCHING':
        case 'GROUPS_SAVING':
        case 'GROUPS_REMOVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'GROUPS_FETCHED':
            return Object.assign({}, state, { ajaxLoading: false, ajaxLoaded: true, groups: action.payload });
        case 'GROUPS_SAVED':
            return Object.assign({}, state, { ajaxLoading: false, showEditModal: false });
        case 'GROUPS_REMOVED':
            return Object.assign({}, state, { ajaxLoading: false, showRemoveModal: false });
        default:
            return state;
    }
};

export default groups;
