import get from 'lodash.get';

const defaultState = {
    activeTab: 0,
    ajaxLoading: false,
    ajaxLoaded: false,
    logFetching: false,
    log: null,
    devices: [],
    treeData: [],
    page: 1,
    limit: 30,
    showEditModal: false,
    showRemoveModal: false,
    showUploadModal: false,
    showSettingModal: false,
    showLogModal: false,
    activeRow: {},
    pattern_network_id: 0,
    filters: {location_id: 0, customer_id: 0},
    vpn_running: false,
};

const devices = (state = defaultState, action) => {
    switch (action.type) {
        case 'DEVICES_ACTIVE_TAB':
            return Object.assign({}, state, { activeTab: action.payload });
        case 'DEVICES_SET_FILTER':
            return Object.assign({}, state, { filters: action.payload });
        case 'DEVICES_SET_PAGE':
            return Object.assign({}, state, { page: action.payload });
        case 'DEVICES_SET_LIMIT':
            return Object.assign({}, state, { limit: action.payload });
        case 'DEVICES_SHOW_EDIT_MODAL':
            return Object.assign({}, state, { showEditModal: action.payload });
        case 'DEVICES_SHOW_REMOVE_MODAL':
            return Object.assign({}, state, { showRemoveModal: action.payload });
        case 'DEVICES_SHOW_UPLOAD_MODAL':
            return Object.assign({}, state, { showUploadModal: action.payload });
        case 'DEVICES_SHOW_SETTING_MODAL':
            return Object.assign({}, state, { showSettingModal: action.payload });
        case 'DEVICES_SHOW_LOG_MODAL':
            return Object.assign({}, state, { showLogModal: action.payload });
        case 'DEVICES_SET_NETWORK_MODEL':
            return Object.assign({}, state, { pattern_network_id: action.payload });
        case 'DEVICES_SET_ACTIVE_ROW':
            return Object.assign({}, state, { activeRow: action.payload });
        case 'DEVICES_SET_ROW_VALUE':
            const activeRow = Object.assign({}, state.activeRow, action.payload);
            return Object.assign({}, state, { activeRow });
        case 'DEVICES_FETCHING':
        case 'DEVICES_SAVING':
        case 'DEVICES_REMOVING':
            return Object.assign({}, state, { ajaxLoading: true });
        case 'DEVICES_FETCHED':
            return Object.assign({}, state, {
                ajaxLoading: false,
                ajaxLoaded: true,
                devices: get(action.payload, 'devices', []),
                treeData: get(action.payload, 'tree', [])
            });
        case 'DEVICE_LOGS_FETCHING':
            return Object.assign({}, state, { logFetching: true });
        case 'DEVICE_VPN_RUNNING':
            return Object.assign({}, state, { vpn_running: action.payload });
        case 'DEVICE_LOGS_FETCHED':
            return Object.assign({}, state, { logFetching: false, log: action.payload });
        case 'DEVICES_SAVED':
            return Object.assign({}, state, { ajaxLoading: false, showEditModal: action.payload, showSettingModal: false });
        case 'DEVICES_REMOVED':
            return Object.assign({}, state, { ajaxLoading: false, showRemoveModal: false });
        default:
            return state;
    }
};

export default devices;
