const defaultState = {
    listLoading: false,
    uploading: false,
    removing: false,
    device_images: [],
    titles: {},
};

const device_images = (state = defaultState, action) => {
    switch (action.type) {
        case 'DEVICE_IMAGES_SET_TITLE':
            let titles = {};
            titles['title_'+action.payload.id] = action.payload.title;
            return Object.assign({}, state, {titles: Object.assign({}, state.titles, titles)});
        case 'DEVICE_IMAGES_FETCHING':
            return Object.assign({}, state, { listLoading: true });
        case 'DEVICE_IMAGES_SAVING':
            return Object.assign({}, state, { uploading: true });
        case 'DEVICE_IMAGES_REMOVING':
            return Object.assign({}, state, { removing: true });
        case 'DEVICE_IMAGES_FETCHED':
            return Object.assign({}, state, { listLoading: false, device_images: action.payload });
        case 'DEVICE_IMAGES_SAVED':
            return Object.assign({}, state, { uploading: false });
        case 'DEVICE_IMAGES_REMOVED':
            return Object.assign({}, state, { removing: false });
        default:
            return state;
    }
};

export default device_images;
